import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const advancedInstructions = () => {
  return (
    <Layout>
      <SEO
        title="Advanced Instructions in PES 2021"
        description="Here is every Advanced Instruction you can use in PES 2021, for both Attack and Defence. Anchoring was added in last year's edition."
      />
      <div className="container">
        <h1>Advanced Instructions in PES 2021</h1>
        <ul className="breadcrumb" style={{ paddingTop: "1rem" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Advanced Instructions in PES 2021</li>
        </ul>
        <p>
          In the Game Plan screen, under "Preset Tactics", you can define
          Advanced Instructions for your team - up to 2 for Attack and 2 for
          Defence.
        </p>
        <p>
          By default, they will be activated when you start every match, but you
          can toggle them ON/OFF by pressing L2 + the direction of the
          instruction in the D-pad.
        </p>
        <p>
          These are all the Advanced Instructions available in eFootball PES
          2021 - you can use them with any manager you choose. "Anchoring" is
          the most recent instruction, added in PES 2020.
        </p>
        <h2 id="attackinginstructions">Attack</h2>
        <h3 className="bullet">Anchoring</h3>
        <p>
          Selected player is restricted from drifting out of position
          horizontally. For example, your centre forward will keep to a central
          position, and your wingers will not venture inside.
        </p>
        <h3 className="bullet">False Winger</h3>
        <p>
          Wingers (or wide midfielders when no wingers are present) who stray
          from their traditional wide positions to play more centrally. When a
          false winger comes inside, the full back on the same side will move
          into advanced positions.
        </p>
        <h3 className="bullet">Defensive</h3>
        <p>Designated players will refrain from pushing forward in attack.</p>
        <h3 className="bullet">Hug the Touchline</h3>
        <p>
          Players on both flanks stay close to the touchline. This means that
          even if the ball is on the other side of the pitch, the player on the
          flank will stay wide.
        </p>
        <h3 className="bullet">Attacking Full Backs</h3>
        <p>
          Both full backs will surge forward while midfielders drop back to
          cover them. As the full backs push forward, wingers will move towards
          the middle.
        </p>
        <h3 className="bullet">Wing Rotation</h3>
        <p>
          When a player is in possession of the ball on the wing, a teammate
          will head to the touchline to give them passing options. Other
          teammates will then run into the space that has been created.
        </p>
        <h3 className="bullet">Tiki-Taka</h3>
        <p>
          The priority is to keep passing the ball. Players take up positions to
          enable possession to be retained. Players will tend not to try to dash
          into space behind the opponent's defence.
        </p>
        <h3 className="bullet">False No.9</h3>
        <p>
          The striker will frequently drop into the hole to collect passes. As
          he comes back for the ball, his teammates will push forward to fill
          the space he has created.
        </p>
        <h3 className="bullet">Centring Targets</h3>
        <p>
          The aim is to score from crosses. When a player on the wing has
          possession, forwards will get into position in front of the goal and
          wait for the cross.
        </p>
        <h3 className="bullet">False Full Backs</h3>
        <p>
          The full backs stray from their traditional positions and push into
          the centre. This gives the team numbers in the middle of the pitch.
        </p>
        <h2 id="defensiveinstructions">Defence</h2>
        <h3 className="bullet">Wing Back</h3>
        <p>
          Wide midfielders or wingers drop back to cover defensively when
          needed.
        </p>
        <h3 className="bullet">Tight Marking</h3>
        <p>
          Certain players will be marked much tighter than usual, with the
          marker switching depending on the position. Space tends to open up as
          players track the opposition player they're marking.
        </p>
        <h3 className="bullet">Deep Defensive Line</h3>
        <p>
          The defending team drops back to guard against through balls, leaving
          more space between individual players and the opponent they are
          marking. As players are no longer tightly marked, it makes it easier
          to pass the ball to feet.
        </p>
        <h3 className="bullet">Swarm the Box</h3>
        <p>
          When the opponent is pushing forward up the wing, players will group
          in front of goal. As players are bunched near the goal, it becomes
          easier for the attacking team to shoot from far.
        </p>
        <h3 className="bullet">Counter Target</h3>
        <p>
          Certain forwards stay in the vicinity of the opposition's box rather
          than dropping back to help the defence. It means that these forwards
          do not use up energy running back and forward.
        </p>
        <h3 className="bullet">Gegenpress</h3>
        <p>
          When a team loses possession, they immediately try to win it back with
          multiple players putting the player on the ball under pressure. It
          demands a lot of stamina.
        </p>
      </div>
    </Layout>
  )
}

export default advancedInstructions
